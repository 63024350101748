import { Component } from '@angular/core';

@Component({
	selector: 'app-prod',
	templateUrl: 'prod.page.html',
	styleUrls: ['prod.page.scss'],
})
export class ProdPage {
	products = [
		{
			title: 'Etericno ulje',
			dsc: 'Etericno ulje sadrži tri glavne komponente(artemizija keton, 1, 8 - cineol i i kamfor), stoga ima snažan učinak u borbi protiv mikroorganizama.Koristimo ga za inhaliranje kod poteškoća s dišnim putevima…pomaže kod začepljenognosa, bronhijalnih tegoba.Uvijek ga možemo imati u džepu, torbici, autu i jednostavno samo udisati kad god to želimo.',

			items: [{
				src: '10.jpg'
			},
			{
				src: 'DZ.jpg'
			},
			{
				src: '30.jpg'
			},
			{
				src: '40.jpg'
			},

			{
				src: '20.jpg'
			},

			{
				src: '50.jpg'
			},
			{
				src: 'S.jpg'
			}],

		},

		{
			title: 'Masazno ulje',
			dsc: 'Daje koži elastičnost, smanjuje svrbež, opušta mišiće.',

			items: [
				{
					src: 'I.jpg'
				},
				{
					src: 'O.jpg'
				},
				{
					src: 'P.jpg'
				}]
		},

		{
			title: 'Tinktura',
			dsc: 'Tinktura ima snažan učinak u podizanju imuno sustava, pospješuje funkciju jetara, djeluje kod upalnih procesa u organizmu, uspješno odstranjuje parazite, pomaže kod Kronove bolesti.',

			items: [

				{
					src: 'J.jpg'
				},
				{
					src: 'K.jpg'
				},
				{
					src: 'T.jpg'
				},
				{
					src: 'U.jpg'
				},
				{
					src: 'V.jpg'
				}]
		},
		{
			title: 'Hidrolat',
			dsc: 'Hidrolat slatkog pelina koristimo u vidu obloga kod upale mišića, oteklih nogu i za bolju cirkulaciju. On ublažava crvenilo na koži izazvano upalnim procesima (npr. kod prištića i akni), a koristimo ga i za ispiranje grla kod upala te generalno za higijenu usne šupljine.',

			items: [

				{
					src: 'L.jpg'
				},
				{
					src: 'M.jpg'
				},
				{
					src: 'N.jpg'
				},
				{
					src: 'W.jpg'
				}]
		},
		{
			title: 'Čaj slatkog pelina',
			dsc: 'Najbolje ga je koristiti kao prevenciju u borbi protiv gripe, crijevnih i želučanih problema.',

			items: [

				{
					src: 'Q.jpg'
				},
				{
					src: 'Y.jpg'
				}]
		},
		{
			title: 'Melem',
			dsc: 'Snažno djeluje na bolove u zglobovima, mišićima, kod bolova artritisa, povreda i upala tetiva. Pomaže u rješavanju i ublažavanju kožnih problema izazvanih ekcemima, lišajevima, psorijazom, alergijskim osipima, a naročito se pokazao djelotvoran u suzbijanju herpesa usana u ranoj fazi. Melem za usne Čuva usne od isušivanja, ubrzava proces zacjeljivanja ispucanih, pojavom prvih simptoma herpesa onesposobljava njegov daljnji razvoj. Slatki peli se može koristiti i kao začin,',

			items: [

				{
					src: 'R.jpg'
				}]
		},
		{
			title: 'Collection',
			dsc: 'slatki pelin',

			items: [
				{
					src: 'X.jpg'
				},
				{
					src: 'Z.jpg'
				}
			]
		},
	];

	constructor() { }
}

