import { Component } from '@angular/core';

@Component({
    selector: 'app-contact',
    templateUrl: 'contact.page.html',
    styleUrls: ['contact.page.scss'],
})
export class ContactPage {

    constructor() { }

}
