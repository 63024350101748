import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';

import { AppRoutes } from './app-routing.module';

import { HomePage } from './home/home.page';
import { ProdPage } from './prod/prod.page';
import { AboutPage } from './about/about.page';
import { ContactPage } from './contact/contact.page';


@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        ProdPage,
        AboutPage,
        ContactPage,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutes,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
