import { RouterModule, Routes } from '@angular/router';
import { HomePage } from './home/home.page';
import { ProdPage } from './prod/prod.page';
import { AboutPage } from './about/about.page';
import { ContactPage } from './contact/contact.page';

const routes: Routes = [
    {
        path: 'home', component: HomePage,
    },
    {
        path: 'prod', component: ProdPage,
    },
    {
        path: 'about', component: AboutPage,
    },
    {
        path: 'contact', component: ContactPage,
    },
    {
        path: '', redirectTo: 'home', pathMatch: 'full'
    },
];

export const AppRoutes = RouterModule.forRoot(routes, { useHash: true });
